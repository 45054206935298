<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <base-card>
          <v-card-title> Actions </v-card-title>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-btn :to="{
                  name: 'Product Skeleton Edit',
                  params: {
                    productSkeletonId: $route.params.productSkeletonId
                  }
                }">
                  Edit <v-icon class="ml-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn :to="{ name: 'Edit Global Product Skeleton', params: { productSkeletonId: $route.params.productSkeletonId } }" class="mt-2">
                  Change all existing Products
                  <v-icon class="ml-2">
                    mdi-earth-arrow-right
                  </v-icon>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </base-card>
      </v-col>
      <v-col cols="6">
        <base-card>
          <v-card-title> Product Skeleton Details </v-card-title>
          <trev-product-skeleton-view-card
            :productSkeleton="prodSkel"
            :title="prodSkel.name"
          >
          </trev-product-skeleton-view-card>
        </base-card>
      </v-col>
      <v-col cols="3">
        <base-card>
          <v-card-title> Links </v-card-title>
          <v-list>
            <v-list>
              <v-list-item
                v-if="
                  prodSkel.metaData &&
                  prodSkel.metaData.some((x) =>
                    x.value.includes('Type:PaxProduct')
                  )
                "
              >
                <v-list-item-content>
                  <v-list-item-title> Pax 8 Link </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    target="_"
                    :href="'https://app.pax8.com/#/products/' + getPaxProductId"
                  >
                    <v-icon> mdi-open-in-new </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-list>
        </base-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TrevProductSkeletonAdd from "../../../../../components/products/productskeletons/trev-product-skeleton-add.vue";
import trevProductSkeletonViewCard from "../../../../../components/products/productskeletons/trev-product-skeleton-view-card.vue";
export default {
  components: { trevProductSkeletonViewCard, TrevProductSkeletonAdd },
  metaInfo: () => ({
    title: "Product Skeleton View",
  }),
  computed: {
    doesHavePaxProductLink() {
      return this.prodSkel.metaData.some((x) =>
        x.value.includes("Type:PaxProduct")
      );
    },
    getPaxProductId() {
      return this.prodSkel.metaData
        .filter((x) => x.value.includes("TypeId:"))[0]
        .value.replace("TypeId:", "");
    },
  },
  created() {
    this.getData();
  },
  methods: {
    updatedData(){
      this.isEdit = false;
      this.getData();
    },
    getData() {
      this.$courier.ProductSkeletons.getById(
        this.$route.params.productSkeletonId
      ).then((x) => {
        this.prodSkel = x;

        this.$courier.ProductSkeletons.withId(x.id)
          .PriceRules.getData(1, 999, "")
          .then((p) => {
            this.prodSkel.priceRules = p.payload;
          });

        this.$courier.ProductSkeletons.withId(x.id)
          .MetaData.getData(1, 999, "")
          .then((m) => {
            this.prodSkel.metaData = m.payload;
          });
      });
    },
  },
  data() {
    return {
      isEdit: false,
      prodSkel: {},
    };
  },
};
</script>

<style>
</style>