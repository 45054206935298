<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <h5>{{ title }}</h5>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'"
            >SKU:{{ productSkeleton.skuNumber }}</span
          >
        </v-list-item-title>
        <v-list-item-subtitle> SKU Number </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'">{{
            productSkeleton.division
          }}</span>
        </v-list-item-title>
        <v-list-item-subtitle> Division </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'">{{
            productSkeleton.supplier
          }}</span>
        </v-list-item-title>
        <v-list-item-subtitle> Supplier </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'"
            >£{{ productSkeleton.price }}</span
          >
        </v-list-item-title>
        <v-list-item-subtitle> Price </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="xeroTenant.organisationName">
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'">
            {{xeroTenant.organisationName}}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle>
          Xero Tenant
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="xeroAccountCode.code">
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'">
            {{xeroAccountCode.code}} - {{xeroAccountCode.name}}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle>
          Xero Default Account Code
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="productSkeleton.unitCharged">
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'">{{
            productSkeleton.unitCharged
          }}</span>
        </v-list-item-title>
        <v-list-item-subtitle> Unit Charged </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="productSkeleton.chargeFrequency">
      <v-list-item-content>
        <v-list-item-title>
          <span :class="textColor + '--text'">{{
            productSkeleton.chargeFrequency
          }}</span>
        </v-list-item-title>
        <v-list-item-subtitle> Charge Frequency </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="productSkeleton.hasAlerts">
      <v-list-item-content>
        <v-list-item-title>
          Will alert
          <span :class="textColor + '--text'">{{
            productSkeleton.alertOffsetInDays
          }}</span>
          Days <br />
          before expiry to
          <span :class="textColor + '--text'">{{
            productSkeleton.alertGroup
          }}</span>
        </v-list-item-title>
        <v-list-item-subtitle> Alerting </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-if="productSkeleton.metaData && productSkeleton.metaData.length > 0"
    >
      <v-list-item-content>
        <v-list-item-title
          v-for="(tag, index) in productSkeleton.metaData"
          :key="index"
        >
          <span :class="textColor + '--text'">
            {{ tag.value }}
          </span>
        </v-list-item-title>
        <v-list-item-subtitle> Metadata </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      v-if="productSkeleton.priceRules && productSkeleton.priceRules.length > 0"
    >
      <v-list-item-content>
        <v-list-item-title
          v-for="(priceRule, index) in productSkeleton.priceRules"
          :key="index"
        >
          <span :class="textColor + '--text'"
            >£{{ priceRule.pricePerUnit }} when more than
            {{ priceRule.targetVariableOrProperty }}
            {{ productSkeleton.unitCharged }}s</span
          >
        </v-list-item-title>
        <v-list-item-subtitle> Price Rules </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    textColor: {
      type: String,
      default: "primary",
    },
    title: {
      type: String,
      default: "My Product Skeleton",
    },
    productSkeleton: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    productSkeleton: {
      immediate: true,
      deep: true,
      handler() {
        if (
          this.productSkeleton &&
          this.productSkeleton.defaultXeroTenantId &&
          this.productSkeleton.defaultXeroAccountCodeId
          && this.productSkeleton.defaultXeroAccountCodeId != '00000000-0000-0000-0000-000000000000'
        ) {
          console.log('test');
          this.getXeroDetails();
        }
      },
    },
  },
  methods: {
    async getXeroDetails() {
      this.xeroTenant = await this.$store.getters.getXeroProfiles.find(
        (x) => x.tenantId == this.productSkeleton.defaultXeroTenantId
      );

      this.$courier.XeroAccountExternal.getById(
        this.productSkeleton.defaultXeroAccountCodeId,
        [
          {
            key: "tenantId",
            value: this.productSkeleton.defaultXeroTenantId,
          },
        ]
      ).then((accountCodeResp) => {
        this.xeroAccountCode = accountCodeResp;
      });
    },
  },
  data() {
    return {
      xeroTenant: {},
      xeroAccountCode: {},
    };
  },
};
</script>

<style></style>
