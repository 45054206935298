var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('base-card',[_c('v-card-title',[_vm._v(" Actions ")]),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-btn',{attrs:{"to":{
                name: 'Product Skeleton Edit',
                params: {
                  productSkeletonId: _vm.$route.params.productSkeletonId
                }
              }}},[_vm._v(" Edit "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mt-2",attrs:{"to":{ name: 'Edit Global Product Skeleton', params: { productSkeletonId: _vm.$route.params.productSkeletonId } }}},[_vm._v(" Change all existing Products "),_c('v-icon',{staticClass:"ml-2"},[_vm._v(" mdi-earth-arrow-right ")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('base-card',[_c('v-card-title',[_vm._v(" Product Skeleton Details ")]),_c('trev-product-skeleton-view-card',{attrs:{"productSkeleton":_vm.prodSkel,"title":_vm.prodSkel.name}})],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('base-card',[_c('v-card-title',[_vm._v(" Links ")]),_c('v-list',[_c('v-list',[(
                _vm.prodSkel.metaData &&
                _vm.prodSkel.metaData.some(function (x) { return x.value.includes('Type:PaxProduct'); }
                )
              )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Pax 8 Link ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","target":"_","href":'https://app.pax8.com/#/products/' + _vm.getPaxProductId}},[_c('v-icon',[_vm._v(" mdi-open-in-new ")])],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }